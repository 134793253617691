import React, { useState, useEffect } from "react";
import BHTlogo from "../assests/bht/055555555555555.png";
import emailLogo from "../assests/bht/mailbox-svgrepo-com.png";
import wedsiteLogo from "../assests/bht/world-wide-web-svgrepo-com (1).png";
import addressLogo from "../assests/bht/Path 184.png";
import detailslogo from "../assests/bht/hindu-temple-svgrepo-com.png";
import jsPDF from "jspdf";
import moment from "moment/moment";
import axios from "axios";
import fs from "file-saver";
import { toast } from "react-toastify";

const generatePDF = async (props) => {
  console.log("propss", props);
  console.log("propsof0", props[0]);
  console.log("generate", `${props[0].pdf}`);
  const imageBase64 = props[0].Signature;
  var date = moment(props[0].Date);
  var formattedDate = date.format("YYYY/MM/DD");
  var activities = props[0].InterestInCommitteeActivities;
  var completeActivities = activities.join("\n");
  // Create a new instance of jsPDF
  const doc = new jsPDF();

  // Add content to the PDF
  // doc.text('Hello, World!', 10, 10);
  var x = 10;
  var y = 10;
  var width = 20;
  var height = 20;
  // Add the image to the PDF document
  doc.addImage(BHTlogo, "PNG", x, y, width, height);
  // Set the position and style of the text
  var textX = x + width + 10;
  var textY = y + height / 2;
  doc.setFontSize(18);
  doc.setTextColor("#1B8202");
  doc.setFont("helvetica", "bold");
  // Add the text to the PDF document
  doc.text("BEDFORD HINDU TEMPLE AND COMMUNITY TRUST", textX, textY);
  doc.setTextColor("#F6821F");
  doc.setFontSize(14);
  doc.setFont("helvetica", "bold");
  doc.text("Monthly Donor Form", 80, 40);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  var textBoxWidth = 80;
  var textBoxHeight = 10;
  var textBoxMargin = 30;
  var textBoxX = 10;
  var textBoxY = 60;

  var label1 = "Name";
  var label2 = "Spouses Name";
  var text1 = `${props[0].Name}`;
  var text2 = `${props[0].SpouseName}`;

  // First Name label and text box
  doc.text(textBoxX, textBoxY - 6, label1);
  doc.rect(textBoxX, textBoxY, textBoxWidth, textBoxHeight);
  doc.text(text1, textBoxX + 5, textBoxY + 5);

  // Last Name label and text box
  var textBox2X = textBoxX + textBoxWidth + textBoxMargin;
  doc.text(textBox2X, textBoxY - 6, label2);
  doc.rect(textBox2X, textBoxY, textBoxWidth, textBoxHeight);
  doc.text(text2, textBox2X + 5, textBoxY + 5);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  var textBoxWidth1 = 80;
  var textBoxHeight1 = 10;
  var textBoxMargin1 = 30;
  var textBoxX1 = 10;
  var textBoxY1 = 85;

  var label1 = "Email";
  var label2 = "ContactNumber";
  var text1 = `${props[0].Email}`;
  var text2 = `${props[0].ContactNumber}`;

  // First Name label and text box
  doc.text(textBoxX1, textBoxY1 - 6, label1);
  doc.rect(textBoxX1, textBoxY1, textBoxWidth1, textBoxHeight1);
  doc.text(text1, textBoxX1 + 5, textBoxY1 + 5);

  // Last Name label and text box
  var textBox2X = textBoxX1 + textBoxWidth1 + textBoxMargin1;
  doc.text(textBox2X, textBoxY1 - 6, label2);
  doc.rect(textBox2X, textBoxY1, textBoxWidth1, textBoxHeight1);
  doc.text(text2, textBox2X + 5, textBoxY1 + 5);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  var textBoxWidth2 = 80;
  var textBoxHeight2 = 10;
  var textBoxMargin2 = 30;
  var textBoxX2 = 10;
  var textBoxY2 = 115;

  var label1 = "PostCode";
  var label2 = "Date";
  var text1 = `${props[0].Postcode}`;
  var text2 = `${formattedDate}`;

  // First Name label and text box
  doc.text(textBoxX2, textBoxY2 - 6, label1);
  doc.rect(textBoxX2, textBoxY2, textBoxWidth2, textBoxHeight2);
  doc.text(text1, textBoxX2 + 5, textBoxY2 + 5);

  // Last Name label and text box
  var textBox2X = textBoxX2 + textBoxWidth2 + textBoxMargin2;
  doc.text(textBox2X, textBoxY2 - 6, label2);
  doc.rect(textBox2X, textBoxY2, textBoxWidth2, textBoxHeight2);
  doc.text(text2, textBox2X + 5, textBoxY2 + 5);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  var textBoxWidth3 = 80;
  var textBoxHeight3 = 30;
  var textBoxMargin3 = 30;
  var textBoxX3 = 10;
  var textBoxY3 = 145;

  var label1 = "Address";
  var label2 = "Signature";
  var address = props[0].Address;
  var completeAddress = address.join("\n");
  var text1 = `${completeAddress}`;
  // var text2 = `signature`;
  doc.text(textBoxX3, textBoxY3 - 6, label1);
  doc.rect(textBoxX3, textBoxY3, textBoxWidth3, textBoxHeight3);
  doc.text(text1, textBoxX3 + 5, textBoxY3 + 5);
  var textBox2X = textBoxX3 + textBoxWidth3 + textBoxMargin3;
  doc.text(textBox2X, textBoxY3 - 6, label2);
  doc.rect(textBox2X, textBoxY3, textBoxWidth3, textBoxHeight3);
  if (imageBase64.startsWith("data:image/png;base64,")) {
    // If the input is a base64-encoded PNG image
    doc.addImage(imageBase64, "PNG", 150, 150, 20, 20);
  } else {
    // If the input is text, add it as text to the PDF
    doc.text(imageBase64, 150, 150);
  }
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.text(
    `Other members in your family:  Adults - ${props[0].Adults},    Children - ${props[0].Children}`,
    10,
    190
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.text(
    `Your/your spouse's selected field of expertise: ${props[0].FieldOfExpertise}`,
    10,
    200
  );
  doc.text(
    `Would you/your spouse be willing to assist The Trust in your field of expertise: ${props[0].WillingToAssistFieldOfExpertise}`,
    10,
    210
  );
  doc.text(
    `Please indicate your interest in joining one of the following committees that you would like to \n associate with and help with the Temple & Community activities:`,
    10,
    220
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.text(`${completeActivities}`, 10, 230);
  doc.setTextColor("#F6821F");
  doc.setFont("helvetica", "bold");
  doc.text("Trust's Bank details:", 10, 255);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "Account name:Bedford Hindu Temple & Community Trust \n Bank: Lloyds Bank \n Account Number:73419360 \n Sort Code: 30-98-97",
    40,
    265
  );
  doc.addPage();
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(`Please enroll my family as a regular monthly donor of the Trust and am willing to set up a standing \n order to the Temple Trust’s bank account at the commencement of each month to pay a monthly`, 10, 20)
  doc.setFont("helvetica", "bold");
  doc.text(`Amount: ${props[0].DonationAmount}`, 10,35)
  doc.setFont("helvetica", "normal");
  doc.text("Now we kindly request you to set up a standing order through your bank for the intendend \n monthly donation amount.",10,45)
  doc.setTextColor("#1B8202");
  doc.setFont("helvetica", "bold");
  doc.text(
    "DEVOTEES JOINING AS REGULR MONTHLY DONORS TO SUPPORT THE \n CHARITY’S ACTIVITIES KINDLY USE THE ATTACHED GIFT AID FORM",
    30,
    60
  );
  doc.setTextColor("#F6821F");
  doc.setFont("helvetica", "bold");
  doc.text(
    "(The Trust gratefully appreciates any donation/s to our Hindu Community Centre & \n Temple endeavor)",
    15,
    80
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#F6821F");
  doc.setFont("helvetica", "bold");
  doc.text(
    "Please read, complete, sign the Data Usage Consent form under GDPR 2018 guideline & \n Gift Aid Declartion forms attached",
    10,
    100
  );
  doc.setTextColor("#2884D5");
  doc.setFont("helvetica", "normal");
  doc.textWithLink(
    "Gift Aid Declaration forms",
    10,
    115,
    { url: "https://bhtapps.co.uk/bhtDeclaration" }
  );
  doc.setTextColor("#2884D5");
  doc.setFont("helvetica", "normal");
  doc.textWithLink(
    "Data Usage Consent form",
    80,
    115,
    { url: "https://bhtapps.co.uk/bhtDataProtection" }
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  var backgroundColor = "#F0F0F0"; // Replace with your desired background color
  doc.setFillColor(backgroundColor);

  // Define the dimensions of the background section
  var sectionX = 0;
  var sectionY = 120;
  var sectionWidth = 220;
  var sectionHeight = 60;

  // Draw the background section
  doc.rect(sectionX, sectionY, sectionWidth, sectionHeight, "F");

  // Add content to the section
  doc.setFontSize(12);
  doc.setTextColor("#F6821F");
  doc.setFont("helvetica", "bold");
  doc.text("Contact Us", sectionX + 5, sectionY + 10);
  // Add the first image
  doc.addImage(wedsiteLogo, "PNG", 10, 140, 10, 10);
  // Add the first text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("www.bedfordhindutemple.org", 30, 145);
  // Add the second image
  doc.addImage(emailLogo, "PNG", 115, 140, 10, 10);
  // Add the second text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("bedfordhindutemple@gmail.com", 130, 145);
  // Add the first image
  doc.addImage(detailslogo, "PNG", 10, 160, 10, 10);
  // Add the first text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("Registered Charity No. 1185539", 30, 165);
  // Add the second image
  doc.addImage(addressLogo, "PNG", 115, 160, 10, 10);
  // Add the second text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("No. 5, Peel Street, Bedford MK40 2HX", 130, 165);

  // Save the PDF
  if (`${props[0].pdf}` === "yes") {
    doc.save(`${props[0].PrintName} BHTMonthlyDonor.pdf`);
  }
  else {
    console.log("Condition is false. PDF not saved.");
  }
  //   doc.save(`${props[0].PrintName} BHTMonthlyDonor.pdf`);
  const pdfContent = doc.output("datauristring");
  console.log("PDF Content:", pdfContent);

  // Store PDF content in localStorage
  window.localStorage.setItem("pdfContent", pdfContent);
  console.log("Stored PDF Content:", window.localStorage.getItem("pdfContent"));

  // Prepare email form data
  const formData = {
    from: "bhtportal@gmail.com",
    to: [`${props[0].Email},bedfordhindutemple@gmail.com`], // Corrected to remove the comma within the array
    subject: `Thanks ${props[0].PrintName} from Bedford Hindu Temple`,
    html: `<p><span style="font-family: Helvetica; color: rgb(0, 0, 0);">Dear ${props[0].Name},</span></p>
    <p><span style="font-family: Helvetica; color: rgb(0, 0, 0);">We thankfully appreciate and acknowledge your becoming a monthly donor to this noble cause. Please could we request you to also fill in the</span><span class="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak" dir="ltr" style="font-family: Helvetica;">&nbsp; <a data-fr-linked="true" href="https://bhtapps.co.uk/bhtDeclaration">Donor Gift Aid Form</a> . <span style="color: rgb(0, 0, 0);">if you have not already done earlier.</span></span></p>
    <p><span class="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak" dir="ltr" style="font-family: Helvetica; color: rgb(0, 0, 0);">To download the Monthly Donor Form, please find the attachment.</span></p>
    <p><span style="font-family: Helvetica; color: rgb(0, 0, 0);">Sincerely,&nbsp;</span></p>
    <p><span style="font-family: Helvetica; color: rgb(0, 0, 0);">Bedford Hindu Temple,</span></p>
    <p><span style="font-family: Helvetica; color: rgb(0, 0, 0);">Registered Charity No. 1185539,</span></p>
    <p><span style="font-family: Helvetica; color: rgb(0, 0, 0);">No. 5, Peel Street,&nbsp;</span></p>
    <p><span style="font-family: Helvetica; color: rgb(0, 0, 0);">Bedford MK40 2HX.</span></p>`,
    content: pdfContent.split(";base64,").pop(), // Extract the base64 content
    filename: `${props[0].PrintName} BHTMonthlyDonor.pdf`,
  };

  // Send email via API
  const res = await axios.post("https://dev-api.bookyourtime.co.uk/emailAPI", formData);
  console.log("Response:", res);

  // Check response status and provide feedback
  if (res.status === 200) {
    return toast.success("Email sent successfully!", {
      position: toast.POSITION.TOP_CENTER,
    });
  } else {
    return toast.error("Failed to send email.", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};
const MonthlyPdfGenerator = (data) => {
  console.log("pdfgenereator", data);
  return generatePDF(data);
};

export default MonthlyPdfGenerator;
