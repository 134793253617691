import React, { useEffect, useState, useRef } from "react";
import classes from "./bhtform.module.css";
import { Link } from "react-router-dom";
import BackArrowImg from "../assests/backArrow.svg";
import BHTlogo from "../assests/bht/055555555555555.png";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "./footer";
import SignatureCanvas from "react-signature-canvas";
import { ToastContainer, toast } from "react-toastify";
import { makePostCall } from "../firebase/user.utils";
import dataProtectionFromPdfGenerator from "./dataprotectionfrompdf.component";
import Modal from "react-bootstrap/Modal";
import successPopUp from "../assests/bht/donate.png";
import downloadicon from '../assests/downloadicon.png'
import moment from "moment";

const DataProtectionForm = () => {
  const initialState = {
    ConsentDataShared: "",
    ConsentImageUsed: "",
    ParentalGuardianConsent: "",
    MembersName: "",
    email: "",
    CreationDate: "",
    Signature: "",
    showSuccessMessage: false,
    showErrorMessage: false,
  };

  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [sign, setSign] = useState();
  const [url, setUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [opencreateQuickLink, setOpencreateQuickLink] = useState(true);
  const [successChangePassword, setSuccessChangePassword] = useState(false);
  const [pdfconfirmation, setPdfconfirmation] = useState(false);
  const [signature, setSignature] = useState(""); // Manage signature directly in state
  const [isSignatureCleared, setIsSignatureCleared] = useState(false);
  const signRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  let dataLoad = [];

  const validateForm = () => {
    const {
      // ConsentDataShared,
      // ConsentImageUsed,
      // ParentalGuardianConsent,
      MembersName,
      email
      // CreationDate,
      // Signature,
    } = form;
    const newErrors = {};
    if (!MembersName || MembersName === "") {
      return toast.error("Please enter MembersName.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!email || email === "") {
      return toast.error("Please enter Email.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!email.includes("@")) {
      return toast.error("Please enter valid Email.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // if (!signature || signature === "") {
    //   return toast.error("Please enter Signature.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    if (!selectedDate || selectedDate === "") {
      return toast.error("Please select Date.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return newErrors;
  };

  const handleClear = (event) => {
    event.preventDefault();
    if (signRef.current) {
      signRef.current.clear();
    }
    setSignature("");
    localStorage.removeItem("signature");
    setIsSignatureCleared(true);
  };
  const handleGenerate = (event) => {
    event.preventDefault();
    const trimmedSignature = signRef.current.getTrimmedCanvas().toDataURL("image/png");
    const emptySignaturePlaceholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

    if (!trimmedSignature || trimmedSignature === emptySignaturePlaceholder) {
      setIsLoading(false); // Reset loading if signature is empty
      return toast.error("Signature is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setSignature(trimmedSignature);
    localStorage.setItem("signature", trimmedSignature);
    return toast.success("Signature Saved Successfully.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    // Load the signature data from local storage when the component mounts
    const storedSignature = localStorage.getItem("signature");
    if (storedSignature) {
      setSignature(storedSignature);
    }

    // Add event listener for window resize
    const handleResize = () => {
      // Re-render the signature on resize
      if (signRef.current) {
        signRef.current.fromDataURL(signature);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, [signature]); // Re-run the effect when the signature changes

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onCloseModal1 = () => {
    setSuccessChangePassword(false);
    setPdfconfirmation(false)
  };
  
  const handleYes = () => {
    const trimmedSignature = signRef.current.getTrimmedCanvas().toDataURL("image/png");
    const emptySignaturePlaceholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

    if (!trimmedSignature || trimmedSignature === emptySignaturePlaceholder) {
      setIsLoading(false); // Reset loading if signature is empty
      return toast.error("Signature is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setSignature(trimmedSignature);
    localStorage.setItem("signature", trimmedSignature);

    const data = {
      MemberName: form.MembersName,
      ConsentDataShared: form.ConsentDataShared === "Yes" ? "Yes" : "No",
      ConsentImageUsed: form.ConsentImageUsed === "Yes" ? "Yes" : "No",
      ParentalGuardianConsent:
        form.ParentalGuardianConsent === "Yes" ? "Yes" : "No",
      MemberSignature: trimmedSignature,
      EmailId: form.email,
      // MemberSignature: url,
      Date: moment(selectedDate).format("YYYY-MM-DD"),
      pdf: "yes"
    };
    dataLoad.push(data);
    dataLoad.push(data);
    console.log("datalaod", dataLoad)
    dataProtectionFromPdfGenerator(dataLoad);

    setForm(initialState);
    //sign.clear();
    setSuccessChangePassword(true);
    setSelectedDate("");
    signRef.current.clear();
    setSignature("");
    localStorage.removeItem("signature");
    setIsSignatureCleared(true);
  };

  const handleNo = () => {
    const trimmedSignature = signRef.current.getTrimmedCanvas().toDataURL("image/png");
    const emptySignaturePlaceholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

    if (!trimmedSignature || trimmedSignature === emptySignaturePlaceholder) {
      setIsLoading(false); // Reset loading if signature is empty
      return toast.error("Signature is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setSignature(trimmedSignature);
    localStorage.setItem("signature", trimmedSignature);

    const data = {
      MemberName: form.MembersName,
      ConsentDataShared: form.ConsentDataShared === "Yes" ? "Yes" : "No",
      ConsentImageUsed: form.ConsentImageUsed === "Yes" ? "Yes" : "No",
      ParentalGuardianConsent:
        form.ParentalGuardianConsent === "Yes" ? "Yes" : "No",
      MemberSignature: trimmedSignature,
      EmailId: form.email,
      // MemberSignature: url,
      Date: moment(selectedDate).format("YYYY-MM-DD"),
      pdf: "no"
    };
    dataLoad.push(data);
    console.log("datalaod", dataLoad)
    dataProtectionFromPdfGenerator(dataLoad);

    setForm(initialState);
    //sign.clear();
    setSuccessChangePassword(true);
    setSelectedDate("");
    signRef.current.clear();
    setSignature("");
    localStorage.removeItem("signature");
    setIsSignatureCleared(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return; // Exit function if there are validation errors
    }

    setIsLoading(true); // Start loading state

    const trimmedSignature = signRef.current.getTrimmedCanvas().toDataURL("image/png");
    const emptySignaturePlaceholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

    if (!trimmedSignature || trimmedSignature === emptySignaturePlaceholder) {
      setIsLoading(false); // Reset loading if signature is empty
      return toast.error("Signature is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setSignature(trimmedSignature);
    localStorage.setItem("signature", trimmedSignature);

    try {
      // Prepare data for API call
      const data = {
        MemberName: form.MembersName,
        ConsentDataShared: form.ConsentDataShared === "Yes" ? "Yes" : "No",
        ConsentImageUsed: form.ConsentImageUsed === "Yes" ? "Yes" : "No",
        ParentalGuardianConsent:
          form.ParentalGuardianConsent === "Yes" ? "Yes" : "No",
        MemberSignature: trimmedSignature,
        EmailId: form.email,
        // MemberSignature: url,
        Date: moment(selectedDate).format("YYYY-MM-DD"),
      };
      dataLoad.push(data);

      // Define success and failure callbacks for API call
      const onSuccess = (data) => {
        if (data.Status === "Success") {
          setPdfconfirmation(true);
          setResult(data.data);
          setOpencreateQuickLink(true); // Set state or handle as needed
        } else {
          setErrorMessage(true); // Handle error case
        }
      };

      const onFailure = (error) => {
        console.error('API call failed:', error);
        setErrorMessage(true); // Handle error case
      };

      // Make API call
      await makePostCall("/gac/gacBHTDataProtection", data)
        .then(onSuccess)
        .catch(onFailure);
    } catch (error) {
      console.error('Error during API call:', error);
      setErrorMessage(true); // Handle error case
    } finally {
      setIsLoading(false); // Ensure loading state is stopped in all cases
    }
  };

  const containerStyle = {
    display: "flex",
    alignItems: "center",
  };

  const labelStyle = {
    flex: 1, // This will make the label take up the available space
    width: "96%",
  };
  const paragraphStyle = {
    textAlign: "justify", // Justify text for consistent start and end points
  };

  const checkboxStyle = {
    width: "1.5em",
    height: "1.5em",
    border: "2px solid #006089",
  };
  const headingStyle = {
    textAlign: "center",
    color: "#F6821F",
  };
  const inputGroupStyle = {
    display: "flex",
    gap: 35,
    flexWrap: "wrap",
  };

  return (
    <div>
      <div className={classes.title}>
        <h5>
          <Link to={"/"} className={classes[`BHT-title`]}>
            <img src={BackArrowImg} alt="back-arrow-icon" height={15} />
            <span>back to Home</span>
          </Link>
        </h5>
      </div>
      {successChangePassword ? (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          onHide={onCloseModal1}
          contentClassName={classes[`success-modal`]}
        >
          <Modal.Header closeButton className={classes[`modal-header`]}>
            <Modal.Title></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={classes.resetpassword}>
              <div>
                <img
                  src={successPopUp}
                  alt="login-page-iage"
                  className={classes[`successchangepassword-icon`]}
                />
              </div>

              <span className={classes[`success-text`]}>Thank you!</span>
              <p className={classes[`success-modal-desc`]}>
                The Data Protection Form submitted successfully
              </p>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      {pdfconfirmation ? (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          // onHide={onCloseModal1}
          contentClassName={classes[`success-modal`]}
        >
          <Modal.Header closeButton className={classes[`modal-header`]}>
          </Modal.Header>

          <Modal.Body>
            <div className={classes.resetpassword}>
              <div>
                <img
                  src={downloadicon}
                  alt="login-page-iage"
                  className={classes[`successchangepassword-icon`]}
                />
              </div>

              <span className={classes[`success-text`]}>Would you like to download the PDF?</span>
              {/* Add buttons for Yes and No */}
              <div className={classes[`button-container`]}>
                <button onClick={handleYes} className={classes[`confirm-btn`]}>Yes</button>
                <button onClick={handleNo} className={classes[`confirm-btn`]}>No</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <Form>
        <div className={classes.formWrapper}>
          <div className={classes.bhtform}>
            <div className={classes.header}>
              <div className={classes.logo}>
                <img src={BHTlogo} />
              </div>
              <h1 className={classes.heading}>
                BEDFORD HINDU TEMPLE AND COMMUNITY TRUST
              </h1>
            </div>
            <div>
              {/* Data Protection Text */}
              <div className={classes.dataProtectionText}>
                <h6 style={headingStyle} className={classes.para}>
                  Consent to usage of personal information – under GDPR 2018
                  guidelines Addendum to MEMBERSHIP APPLICATION FORM
                </h6>
                <h6 style={headingStyle} className={classes.para}>
                  Data Protection
                </h6>
                <p className={classes.myParagraph} style={paragraphStyle}>
                  <label style={labelStyle}>
                    The information you provide in this form will be used solely
                    for dealing with you as a member of Bedford Hindu Temple and
                    Community Trust (The Trust).
                  </label>
                </p>
                <p className={classes.myParagraph} style={paragraphStyle}>
                  <label style={labelStyle}>
                    The Trust has a Data Privacy Policy which can be found at{" "}
                    <a
                      href="https://www.bedfordhindutemple.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.bedfordhindutemple.org
                    </a>
                    . Your data will be stored in accordance with this policy.
                  </label>
                </p>
                <p className={classes.myParagraph} style={paragraphStyle}>
                  <label style={labelStyle}>
                    The Trust may wish to publish a membership handbook,
                    yearbook or directory. This would include relevant member’s
                    name, email addresses, phone/mobile number, and will be
                    available in either electronic or paper versions.
                  </label>
                </p>
                <p className={classes.myParagraph} style={paragraphStyle}>
                  <div style={containerStyle}>
                    <label style={labelStyle}>
                      If you consent to your data being shared in this way,
                      please tick here.
                    </label>
                    <input
                      id="checkbox1"
                      type="checkbox"
                      style={checkboxStyle}
                      className="form-check-input"
                      checked={form.ConsentDataShared === "Yes"}
                      onChange={(e) =>
                        setField(
                          "ConsentDataShared",
                          e.target.checked ? "Yes" : "No"
                        )
                      }
                    />
                  </div>
                </p>
                <p className={classes.myParagraph} style={paragraphStyle}>
                  <label style={labelStyle}>
                    Please be aware that if you later decide to withdraw consent
                    to your contact details being published it will not be
                    possible to remove your contact details from printed
                    material until such time as the next edition of the handbook
                    is printed, which will be on a [yearly] basis.
                  </label>
                </p>
                <p className={classes.myParagraph} style={paragraphStyle}>
                  <label style={labelStyle}>
                    The Trust may arrange for photographs or videos to be taken
                    of The Trust’s activities and published on our website or
                    social media channels for promotional purposes.
                  </label>
                </p>
                <p className={classes.myParagraph}>
                  <div style={containerStyle}>
                    <label style={labelStyle}>
                      If you consent to your image being used by The Trust in
                      this way, please tick here.
                    </label>
                    <input
                      id="checkbox2"
                      type="checkbox"
                      style={checkboxStyle}
                      className="form-check-input"
                      checked={form.ConsentImageUsed === "Yes"}
                      onChange={(e) =>
                        setField(
                          "ConsentImageUsed",
                          e.target.checked ? "Yes" : "No"
                        )
                      }
                    />
                  </div>
                </p>
                <p className={classes.myParagraph} style={paragraphStyle}>
                  <div style={containerStyle}>
                    <div>
                      <label style={labelStyle}>
                        PARENTAL/GUARDIAN CONSENT (if children under 18) If you
                        consent to the use of your child’s image being used for
                        the purposes above, please tick here.
                      </label>
                    </div>
                    <div>
                      <input
                        id="checkbox3"
                        type="checkbox"
                        style={checkboxStyle}
                        checked={form.ParentalGuardianConsent === "Yes"}
                        className="form-check-input"
                        onChange={(e) =>
                          setField(
                            "ParentalGuardianConsent",
                            e.target.checked ? "Yes" : "No"
                          )
                        }
                      />
                    </div>
                  </div>
                </p>
                <p className={classes.myParagraph}>
                  <label style={labelStyle}>
                    If you or the individuals listed above later wish to
                    withdraw consent, please contact
                    Chairman/Vice-Chairman/Secretary of The Trust.
                  </label>
                </p>
                <p className={classes.myParagraph}>
                  <label style={labelStyle}>
                    By agreeing to your images being used, you agree to assign
                    any copyright or any other right of ownership of these
                    images to The Trust.
                  </label>
                </p>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="Member’s Name">
                      Member’s Name:
                    </label>
                    <br></br>
                    <input
                      type="text"
                      placeholder="Member’s Name"
                      name="Member’s Name"
                      value={form.MembersName}
                      onChange={(e) => setField("MembersName", e.target.value)}
                      className={classes.memberFormInput}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="Member’s Email">
                      Member’s Email:
                    </label>
                    <br></br>
                    <input
                      type="email"
                      placeholder="Member’s Email"
                      name="Member’s Email"
                      value={form.email}
                      onChange={(e) => setField("email", e.target.value)}
                      className={classes.memberFormInput}
                    />
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput}>Signed</label>
                    <br></br>
                    <div
                      className={classes.signCanvasWrapper}
                      style={{
                        border: "1px solid black",
                        height: 200,
                        marginBottom: "20px",
                      }}
                    >
                      <SignatureCanvas
                        canvasProps={{ className: "signature-pad" }}
                        // ref={(data) => setSign(data)}
                        ref={(ref) => (signRef.current = ref)}
                      />
                      <div className={classes.btngroup}>
                        <button name="clear" onClick={handleClear}>
                          Clear
                        </button>
                        <button name="save" onClick={handleGenerate}>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="dateInput">
                      Date
                    </label>
                    <br></br>
                    <div>
                      <DatePicker
                        className={classes.dateFormInput}
                        id="dateInput"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <div className={classes.submit}>
          <button
            name="submit"
            type="submit"
            onClick={handleSubmit}
            className={classes[`submit-btn`]}
            disabled={isLoading} // Disable button when loading
          >
            {isLoading ? (
              <span>Loading...</span> // Replace with your spinner component or text
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </Form>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default DataProtectionForm;
